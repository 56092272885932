import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Cookies from "universal-cookie";

import CheckCookiesUtil from "../utils/CheckCookiesUtil";
import NavigateUtil from "../utils/NavigateUtil";

import useDocumentTitle from "../hooks/useDocumentTitle";

import CheckRegistrationStatusService from "../services/CheckRegistrationStatusService";
import CheckVolunteerService from "../services/CheckVolunteerService";
import EventService from "../services/EventService";

import CountDownComponent from "../components/Home/CountDownComponent";
import LoaderComponent from "../components/LoaderComponent";
import TodayComponent from "../components/Home/TodayComponent";
import WelcomeHome from "../components/Home/WelcomeHomeComponent";
import CheckChurchComponent from "../components/Home/CheckChurchComponent";

const cookies = new Cookies();

export default function HomePage({ translate, setIsLoading, account }) {
  const titlePage = translate("home");
  useDocumentTitle(titlePage);

  const navigate = useNavigate();

  const [event, setEvent] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    CheckCookiesUtil();

    const referrerUrl = cookies.get("referrer_url");
    if (referrerUrl) {
      cookies.remove("referrer_url", { path: "/" });
      throw (window.location.href = referrerUrl);
    }

    const fetchData = async () => {
      try {
        await CheckVolunteerService({ account: account, navigate: navigate });

        const event = await EventService({
          account: account,
          navigate: navigate,
          checkEventClosed: false,
        });
        setEvent(event);

        await CheckRegistrationStatusService({
          account: account,
          event: event,
          navigate: navigate,
        });

        await EventService({
          account: account,
          navigate: navigate,
        });
      } catch (error) {
        console.log(error);
        cookies.set("error", error, {
          expires: new Date(cookies.get("expired")),
          path: "/",
        });
      }
    };

    fetchData();

    const step = cookies.get("step");
    NavigateUtil({ navigate, step });

    setIsLoading(false);
  }, [translate, setIsLoading, navigate, account]);

  if (!account) {
    return <LoaderComponent translate={translate} />;
  }

  if (!event) {
    return <LoaderComponent translate={translate} />;
  }

  return (
    <>
      <WelcomeHome translate={translate} account={account} />

      <TodayComponent translate={translate} />

      <CheckChurchComponent translate={translate} account={account} />

      <CountDownComponent
        translate={translate}
        event={event}
        navigate={navigate}
      />
    </>
  );
}
